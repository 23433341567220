import {Component, OnInit} from '@angular/core';
import {AuthServices} from '../services/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import Swal from 'sweetalert2';
import {TnNotificationService} from '../services/tn-notification.service';


@Component({
  selector: 'app-activate-email',
  templateUrl: './activate-email.component.html',
  styleUrls: ['./activate-email.component.scss']
})
export class ActivateEmailComponent implements OnInit {
  constructor(private authService: AuthServices,
              private route: ActivatedRoute,
              private router: Router,
              private notificationService: TnNotificationService ) {
  }

  ngOnInit() {
    const activationCode = this.route.snapshot.queryParams.activationCode;
    /*
        this.authService.findUserDataByActivationCode(activationCode).subscribe((userResult: any) => {
          console.log('result======', userResult.result)activationCode;
    */
    if (activationCode) {
      this.authService.activateEmail(activationCode).subscribe((activate: any) => {
        this.router.navigate(['/login']).then(() => {
          Swal('', 'Email Verified Successfully! Please Login');
        });
      }, (error) => {
        if (error.error.code === 401 && error.error.message === 'EMAIL_LINK_EXPERIED') {
          this.router.navigate(['/login']).then(() => {
            Swal({
              title: 'Link Expired',
              text: 'Your verification link has expired!',
              confirmButtonText: 'Resend email',
              cancelButtonText: 'Close',
              showCloseButton: true,
              allowEscapeKey: false,
              showLoaderOnConfirm: true,
              allowOutsideClick: false,
              backdrop: false
            })
              .then((result) => {
                if (result && result.value === true) {
                  this.authService.sendVerifyEmail(activationCode).subscribe((activate: any) => {
                    this.notificationService.successToast('Mail sent for verification');
                  });
                } else {
                  this.notificationService.errorToast('Email Verification Failed');
                }
              });
          });
        }
        else if(error.error.code === 403 && error.error.message ==='EMAIL_LINK_ALREADY_USED'){
          this.router.navigate(['/login']).then(() => {
            Swal('', 'This email ID is already verified,please try logging in by using your registered email ID and password');
          });
        }
        else if(error.error.code === 401 && error.error.message ==='NOT_FOUND'){
          this.router.navigate(['/login']).then(() => {
            Swal('', 'Your verification link has expired!');
          });
        }
      });
    }
  }
}
