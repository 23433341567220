import { PipeTransform, Pipe } from "@angular/core";

@Pipe({
  name: "veSearchPipe"
})
export class veSearchPipe implements PipeTransform {
  transform(values: any[], filter: string): any {
    if (!values || !values.length) return [];
    if (!filter) return values;
    filter = filter.toUpperCase();
    if (filter && Array.isArray(values)) {
      const keys = Object.keys(values[0]);

      return values.filter(v => {
        return (
          v.subtitle
            .toString()
            .toUpperCase()
            .indexOf(filter) > -1
        );
      });
    }
  }
}
