import { Component, OnInit } from '@angular/core';
import { AuthServices, AuthServices as Auth } from "../services/auth.service";
import { Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";

@Component({
  selector: 'app-terms-and-condition',
  templateUrl: './terms-and-condition.component.html',
  styleUrls: ['./terms-and-condition.component.scss']
})
export class TermsAndConditionComponent implements OnInit {
  userInfo = {}
  constructor(private authService: AuthServices, private router: Router, private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.termsConditionForm = this.formBuilder.group({
      termsCondition: ['', Validators.required]
    });
    this.getSocialMediaInfo()
  }
  getSocialMediaInfo() {
    this.authService.getSocialMediaData().subscribe((data) => {
     this.userInfo = data;
    });
  }
  termsConditionForm: any;
  termsObject: any;
  // constructor(private formBuilder: FormBuilder,) { }

  // ngOnInit() {
  //   this.termsConditionForm = this.formBuilder.group({
  //     termsCondition: ['', Validators.required]
  //   });
  // }

  termsSubmit(data) {
    this.userInfo['tAndCAgree'] = data.termsCondition
    this.authService
    .socialLogin( this.userInfo)
    .subscribe((response: any) => {
      if (response.code === 200 || response.code === 201) {
        localStorage.setItem("currentUser", response.result.accessToken);
        // localStorage.setItem("signinMethod", 'google');
        localStorage.setItem("uuid", response.result.user.uuid);
        //this.notificationService.successToast("Login Successfully");
        // this.loginSuccess = true;
        setTimeout(() => {
          this.router.navigate(["/home-main"]);
        }, 1000);
      }
    });
  }
}
