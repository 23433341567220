import { Injectable, OnInit } from "@angular/core";
import io from "socket.io-client";

const socket = io("http://localhost:4001/");

export class SocketService {
  constructor() {
    socket.on("connect", socketData => {
      console.log("socketData========", socketData);
      socket.on("User Connection Established", data => {
        console.log("channel-1========", data);
      });
    });
  }
}
