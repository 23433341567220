import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./login/login.component";
import { RegisterComponent } from "./register/register.component";
import { HomeComponent } from "./home/home.component";
import { ActivateEmailComponent } from "./activate-email/activate-email.component";
import { TermsAndConditionComponent }from "./terms-and-condition/terms-and-condition.component"
// import { UpdateProfileComponent } from './update-profile/update-profile.component';
// import { MyprofileComponent } from './myprofile/myprofile.component';
import { ProfileComponent } from "./profile/profile.component";
import { AuthGuard } from "./services/auth.guard";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";

import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { OtpComponent } from './otp/otp.component';
import { CampaignListingComponent } from './campaign-listing/campaign-listing.component';
// import {AuthGuard} from './services/auth.guard';
// import {CampaignManagementComponent} from "./application/campaign-management/campaign-management.component"



const routes: Routes = [
  {
    path: "",
    loadChildren: () =>
      import("../app/home/home.modules").then(m => m.HomeModule)
  },
  {
    path: "home",
    loadChildren: () =>
      import("../app/home/home.modules").then(m => m.HomeModule)
  },
  {
    path: "login",
    component: LoginComponent
  },
  {
    path: "forgot-password",
    component: ForgotPasswordComponent
  },
  {
    path: "reset-password",
    component: ResetPasswordComponent
  },
  {
    path: "register",
    component: RegisterComponent
  },
  {
    path: "terms-condotion",
    component: TermsAndConditionComponent
  },
  {
    path: "profile",
    loadChildren: () =>
      import("../app/profile/profile.modules").then(m => m.ProfileModule),
    //canActivate: [AuthGuard]
  },

  {
    path: "verifyEmail",
    component: ActivateEmailComponent
  },

  // {
  //   path: "issuer",
  //   component: IssuerRegistrationComponent
  // },
  {
    path: "issuer",
    loadChildren: () =>
      import("../app/issuer-sec/issuer-sec.module").then(m => m.IssuerSecModule)
  },
  {
    path: "risk",
    loadChildren: () =>
      import("../app/risk/risk.modules").then(m => m.RickModule)
  },
  {
    path: "application",
    loadChildren: () =>
      import("../app/application/application.modules").then(
        m => m.ApplicationModule
      ),
    // canActivate: [AuthGuard]
  },
  {
    path: "donation",
    loadChildren: () =>
      import("../app/donation/donation.modules").then(
        m => m.DonationModule
      )
  },
  {
    path: "reward",
    loadChildren: () =>
      import("../app/reward/reward.modules").then(
        m => m.RewardModule
      )
  },
  {
    path: "otp",
    component: OtpComponent
  },
  {
    path: 'campaign-listing',
    loadChildren: () =>
      import('../app/campaign-listing/campaign-listing.module').then(
        m => m.CampaignListingModule
      ),
  },

  { path: "**", redirectTo: "" }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
