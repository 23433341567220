import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HomeComponent } from "./home/home.component";
import { LoginComponent } from "./login/login.component";
import { CommonModulesModule } from "./common-modules/common-modules.module";
import { RegisterComponent } from "./register/register.component";
import { HttpClientModule } from "@angular/common/http";
import { SocketService } from "./services/socket.service";
import { NgbModule, NgbDateParserFormatter } from "@ng-bootstrap/ng-bootstrap";
import { ToastrModule } from "ngx-toastr";
import { NgxSpinnerModule } from "ngx-spinner";
import { NgSelectModule } from "@ng-select/ng-select";
import { CarouselModule } from "ngx-owl-carousel-o";
import { LightboxModule } from "ngx-lightbox";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ActivateEmailComponent } from "./activate-email/activate-email.component";
import { RickComponent } from "./risk/risk.component";
import { RewardComponent } from "./reward/reward.component";
import { PackagesComponent } from "./packages/packages.component";
import { DonationComponent } from "./donation/donation.component";
import { ApplicationComponent } from "./application/application.component";
import { ProfileComponent } from "./profile/profile.component";
import { AuthGuard } from "./services/auth.guard";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import UploadService from "./services/uploadService.service";
import { PipeModule } from "./modules/pipe.module";
import { CustomNgbDateParserFormatter } from "./common-modules/custom-ngbDateParserFormatter";
import { VideoProcessingService } from './services/video-processing-service'
import {
  SocialLoginModule,
  AuthServiceConfig,
  GoogleLoginProvider,
  FacebookLoginProvider
} from "angularx-social-login";
import { NgxCurrencyModule } from "ngx-currency";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { NgxQRCodeModule } from 'ngx-qrcode2';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { BlockCopyPasteDirective } from "./block-copy-paste.directive";
// import {CampaignManagementComponent} from "./application/campaign-management/campaign-management.component"
// import {RickModule} from '../app/risk/risk.modules'

import { AuthServices } from "./services/auth.service";
import { CurrencyPipe } from "@angular/common";
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { OtpComponent } from './otp/otp.component';
import { CampaignListingComponent } from './campaign-listing/campaign-listing.component';
import { environment } from "../environments/environment";
import { TermsAndConditionComponent } from './terms-and-condition/terms-and-condition.component';
//import { DashboardComponent } from './profile/dashboard/dashboard.component';

// import {HttpErrorInterceptor}  from "./services/httpErrorInterceptor";

const config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider(environment.googleAppId)
  },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider(environment.fbAppId)
  }
]);

export function provideConfig() {
  return config;
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    ProfileComponent,
    ApplicationComponent,
    RegisterComponent,
    DonationComponent,
    PackagesComponent,
    RickComponent,
    RewardComponent,
    ActivateEmailComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    OtpComponent,
    CampaignListingComponent,
    TermsAndConditionComponent,
    //DashboardComponent,
    // SelectApplicationComponent
    // CharityDetailsComponent,

    // AllOfferingComponent,
  ],
  imports: [
    NgxCurrencyModule,
    ReactiveFormsModule,
    FormsModule,
    NgxSpinnerModule,
    NgxDatatableModule,
    BrowserModule,
    AppRoutingModule,
    NgxQRCodeModule,
    CommonModulesModule,
    HttpClientModule,
    LightboxModule,
    NgbModule,
    SocialLoginModule,
    BrowserAnimationsModule,
    NgSelectModule,
    CarouselModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: "toast-bottom-right",
      preventDuplicates: true
    }),
    PipeModule
    // RickModule
  ],
  providers: [
    CurrencyPipe,
    SocketService,
    VideoProcessingService,
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthGuard, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    UploadService,
    {
      provide: NgbDateParserFormatter,
      useFactory: () => new CustomNgbDateParserFormatter('dd-MM-yyyy')
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
