import { Injectable, Input } from "@angular/core";
import { Router } from "@angular/router";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Subject, ReplaySubject, Observable, BehaviorSubject } from "rxjs";
import { AuthService } from "angularx-social-login";
import jwt_decode from "jwt-decode";
@Injectable({
  providedIn: "root"
})
export class AuthServices {

  /* auth urls */
  registerUrl = environment.apiURL + "/auth/register";
  loginUrl = environment.apiURL + "/auth/login";
  verify2FA = environment.apiURL + '/auth/verify-2fa';
  checkEmail = environment.apiURL + '/auth/checkEmail';
  activateEmailURL = environment.apiURL + "/auth/verifyEmail";
  forgotPasswordMailUrl = environment.apiURL + "/auth/forgotPasswordMail";
  forgotPasswordUrl = environment.apiURL + "/auth/forgotPassword";
  getByActivationcodeUrl = environment.apiURL + "/auth/getByActivationCode/";
  sendVerifyEmailURL = environment.apiURL + "/auth/sendVerifyEmail";
  socialLoginUrl = environment.apiURL + "/auth/socialLogin";
  getImageByLocationUrl = environment.apiURL + "/common/getImageByLocation";

  /* User url's */
  userById = environment.apiURL + "/user/userById/";
  updateProfile = environment.apiURL + "/user/updateProfile/";
  deActivateAccountUrl = environment.apiURL + "/user/deactivateAccount";
  resetpassword = environment.apiURL + "/user/changePassword/";


  /* Investor Url's */
  updateIndividualDetailsUrl = environment.apiURL + '/investor/updateIndividualDetails';
  updateCompanyDetailsUrl = environment.apiURL + '/investor/updateCompanyDetails';
  gerVerificationDetailsUrl = environment.apiURL + '/investor/getVerificationDetails';
  updateinvestorBackroundUrl = environment.apiURL + "/investor/updateBackgroundDetails";
  getinvestorBackroundUrl = environment.apiURL + "/investor/getBackgroundDetails";




  /* not using related with auth */
  comparePasswordById = environment.apiURL + "/auth/checkPassword";

  /*not using related with Investor */
  investorById = environment.apiURL + "/investor/investorById/";
  updateinvestor = environment.apiURL + "/investor/addOrUpdateInvestor";


  uploadUrl = environment.apiURL + "/auth/upload";
  check2FAUrl = environment.apiURL + "/2fa/check";
  generate2FAUrl = environment.apiURL + "/2fa/generate";
  confirm2FAUrl = environment.apiURL + "/2fa/confirm";
  disable2FAUrl = environment.apiURL + "/2fa/disable";
  getUserByAuthenticationCode =
    environment.apiURL + "/user/getUserByAuthenticationCode/";

  deleteUserById = environment.apiURL + "/user/deleteUserById/";

  addWallet = environment.apiURL + "/wallet/addWallet";
  walletById = environment.apiURL + "/wallet/walletById/";
  findUserById = environment.apiURL + "/user/userById/";






  useGoogleAuthReactive: Subject<any> = new Subject<any>();
  userData: Subject<any> = new Subject<any>();
  userDataReactive: ReplaySubject<any> = new ReplaySubject<any>();
  pushUserAuthAccountObs: ReplaySubject<any> = new ReplaySubject<any>();
  userDataSubject: Subject<any> = new Subject<any>();
  private applicationData = new BehaviorSubject<object>({});
  private requestIdData = new BehaviorSubject<object>({});
  private socialMediaData = new BehaviorSubject<object>({});
  constructor(private http: HttpClient, private authService: AuthService) { }


  /* auth services */

  register(body) {
    return this.http.post(this.registerUrl, body);
  }

  login(body) {
    return this.http.post(this.loginUrl, body, {
      headers: new HttpHeaders({
        lang: "eng"
      })
    });
  }

  verify2FAOTP(body) {
    return this.http.post<any>(this.verify2FA, body);
  }


  activateEmail(code: string) {
    return this.http.put(this.activateEmailURL, { activationCode: code });
  }

  checkUserEmail(body) {
    return this.http.post(this.checkEmail, body);
  }

  forgotPasswordMail(body) {
    return this.http.post(this.forgotPasswordMailUrl, body);
  }

  forgotPassword(body) {
    return this.http.post(this.forgotPasswordUrl, body);
  }

  getUserByActivationCode(ActivationCode) {
    return this.http.get<any>(this.getByActivationcodeUrl + ActivationCode);
  }
  sendVerifyEmail(code: string) {
    return this.http.post(this.sendVerifyEmailURL, { activationCode: code });
  }

  socialLogin(body) {
    return this.http.post(this.socialLoginUrl, body);
  }


  setSocialMediaData(body: object) {
    this.socialMediaData.next(body);
  }

  getSocialMediaData(): Observable<any> {
    return this.socialMediaData.asObservable();
  }
  setapplicationData(body: object) {
    this.applicationData.next(body);
  }
  getapplicationData(): Observable<any> {
    return this.applicationData.asObservable();
  }

  setRequestId(requestId: object) {
    this.requestIdData.next(requestId);
  }

  getRequestId(): Observable<any> {
    return this.requestIdData.asObservable();
  }

  getProfileImage(query) {
    return this.http.get<any>(
      this.getImageByLocationUrl + `?fileName=${query.fileName}`
    );
  }

  /* user services */

  getUserData() {
    return this.http.get<any>(this.userById + localStorage.getItem("uuid"));
  }

  profileUpdateData(body) {
    return this.http.post(
      this.updateProfile + localStorage.getItem("uuid"),
      body
    );
  }

  deactivateAccount(body) {
    return this.http.post(this.deActivateAccountUrl, body);
  }


  changePassword(body) {
    return this.http.post(
      this.resetpassword + localStorage.getItem("uuid"),
      body
    );
  }

  /* investor services */
  updateIndividualDetails(body) {
    return this.http.post<any>(this.updateIndividualDetailsUrl, body);
  }

  updateCompanyDetail(body) {
    return this.http.post<any>(this.updateCompanyDetailsUrl, body);
  }
  getverificationDetailsDetails() {
    return this.http.get<any>(this.gerVerificationDetailsUrl);
  }
  updateInvestorBackground(body) {
    return this.http.post<any>(this.updateinvestorBackroundUrl, body);
  }

  getInvestorbackgroundDetails() {
    return this.http.get<any>(this.getinvestorBackroundUrl);
  }



  /* not use related with auth*/
  checkPassword(body) {
    return this.http.post(this.comparePasswordById, body);
  }



  /* not using related with investor */
  getInvestorData() {
    return this.http.get<any>(this.investorById + localStorage.getItem("uuid"));
  }
  updateInvestor(body) {
    return this.http.post(this.updateinvestor, body);
  }




  getUserAuthAccountAsObs(): Observable<any> {
    return this.pushUserAuthAccountObs.asObservable() as Observable<any>;
  }

  getUserDataReac(): Observable<any> {
    return this.userDataSubject.asObservable() as Observable<any>;
  }

  getWalletData() {
    return this.http.get<any>(this.walletById + localStorage.getItem("uuid"));
  }

  upload(imageForm) {
    return this.http.post(this.uploadUrl, imageForm);
  }

  returnGoogleAuthObservable() {
    return this.useGoogleAuthReactive.asObservable();
  }

  pushUserAuthInfo(user) {
    this.useGoogleAuthReactive.next(user);
  }
  logout() {
    localStorage.clear();
    this.authService.signOut(true);
  }

  findUserData(id) {
    return this.http.get<any>(this.findUserById + id);
  }
  findUserDataByActivationCode(id) {
    return this.http.get<any>(this.getUserByAuthenticationCode + id);
  }
  deleteAccount(body) {
    return this.http.post(this.deleteUserById, body);
  }
  addwallet(body) {
    return this.http.post(this.addWallet, body);
  }

  downloadFile(url) {
    return this.http.get(url, { responseType: "blob" });
  }

  check2FA(body) {
    return this.http.post(this.check2FAUrl, body);
  }
  generate2FA(body) {
    return this.http.post(this.generate2FAUrl, body);
  }
  confirm2FA(body) {
    return this.http.post(this.confirm2FAUrl, body);
  }
  disable2FA(body) {
    return this.http.post(this.disable2FAUrl, body);
  }
  checkTokenExpiration() {
    if (localStorage.getItem("currentUser")) {
      const decodedToken = jwt_decode(
        localStorage.getItem("currentUser") && localStorage.getItem("currentUser")
      );
      if (decodedToken.exp < Date.now() / 1000) {
        localStorage.clear();
        window.location.href = "/login";
      }
      return;
    }
  }
}