import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthServices } from "../services/auth.service";
import { ToastrService } from "ngx-toastr";
import Swal from 'sweetalert2';
import { TnNotificationService } from "../services/tn-notification.service";
import { MustMatch } from "../helpers/must-watch.validator";

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  forgotPasswordForm: FormGroup;
  submit: boolean = false;
  activationCode: any;
  fieldTextType: boolean;
  cpasswordTextType: boolean;
  resetSuccess = false;
  oldPassword = false;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthServices,
    private notificationService: TnNotificationService
  ) { }
  ONCHANGE(e) {
    var re = /[`~!#$%^&*()_|+\-=?;:'",<>\{\}\[\]\\\/]/;
    if (re.test(e.target.value)) {
      e.preventDefault();
    }
  }
  ngOnInit() {
    this.activationCode = this.route.snapshot.queryParams.activationCode;
    if (this.activationCode) {
      this.authService.getUserByActivationCode(this.activationCode).subscribe((response) => {
      }, error => {
        if (error && error.status === 404) {
          this.router.navigate(['/login']).then(() => {
            Swal('', 'Your link to verify e-mail has expired!');
          });
        }
      });
    }
    this.forgotPasswordForm = this.formBuilder.group(
      {
        password: [
          null,
          [Validators.required, Validators.compose([Validators.minLength(8)])]
        ],
        confirmPassword: ["", Validators.required]
      },
      {
        validator: MustMatch("password", "confirmPassword")
      }
    );


  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
  toggleCPasswordFieldTextType() {
    this.cpasswordTextType = !this.cpasswordTextType;
  }

  forgotSubmit(params) {
    const queryParams = {
      activationCode: this.activationCode,
      password: params.password
    };
    this.authService.forgotPassword(queryParams).subscribe((response: any) => {
      this.resetSuccess = true;
      setTimeout(() => {
        this.router.navigateByUrl("/login").then(res => {
          this.oldPassword = false;
        });
      }, 3000);


    
    }, error => {
      console.log("code",error.error.code);
      
      if(  error.error.code === 403)
        {
         this.oldPassword = true;
        }
    });
  }
}
