import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rick',
  templateUrl: './risk.component.html',
  styleUrls: ['./risk.component.scss']
})
export class RickComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
