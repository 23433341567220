import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  HostListener,
  Injectable
} from "@angular/core";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { Observable, Observer, observable } from "rxjs";
import { AuthServices } from "../../services/auth.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"]
})
@Injectable({
  providedIn: "root"
})
export class HeaderComponent implements OnInit {
  isLoggedIn = false;
  isIssuer = false;
  nativeElement: any;
  uuidValue: string = localStorage.getItem("uuid");
  @ViewChild("menu", { static: false }) menu: ElementRef;
  // isNumber: Function = Number.isNaN as Function;
  currentRoute = false;
  @ViewChild("icon", { static: false }) icon: ElementRef;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthServices
  ) {
    var self = this;
    this.router.events.subscribe(function getCurrentRoute(route) {
      if (route instanceof NavigationEnd) {
        if (route.url == "/login" || route.url == "/register") {
          self.currentRoute = false;
        } else {
          self.currentRoute = true;
        }
      }
    });
  }

  status = true;
  authStatus = false;
  @ViewChild("dataElement", { static: false }) dataElement: ElementRef;

  ngOnInit() {
    // Check isLoggedIn
    this.checkIsLoggedIn(this.uuidValue);
    this.route.queryParams.subscribe(params => {});
    this.getUserData();
  }

  // getUserData
  getUserData() {
    this.authService.getUserData().subscribe(
      (data: any) => {
        if (data) {
          this.isIssuer = data.result.isIssuer;
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  @HostListener("document:click", ["$event"]) onclick(e) {
    if (
      Array.from(e.target.classList).indexOf("notiy") === -1 &&
      !e.target.classList.contains("svg-noti")
    ) {
      if (this.menu) {
        if (this.menu.nativeElement) {
          if (this.menu.nativeElement.classList.contains("drop-visible")) {
            this.menu.nativeElement.classList.remove("drop-visible");
            this.icon.nativeElement.classList.toggle("drop-visible");
          }
        }
      }
    }
  }

  checkIsLoggedIn(uuidValue: string) {
    if (uuidValue) {
      this.isLoggedIn = true;
    }
  }

  clearToken() {
    localStorage.clear();
    this.router.navigateByUrl('/login');
  }

  logout() {
    this.router.navigateByUrl('/login');
    this.authService.logout();
  }

  clickEvent() {
    this.status = !this.status;
  }

  iconClick(e) {
    e.preventDefault();
    this.menu.nativeElement.classList.toggle("drop-visible");
    this.icon.nativeElement.classList.toggle("drop-visible");
  }
}
