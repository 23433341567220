import { Component, OnInit, DoCheck, HostListener } from "@angular/core";
import {
  NgbTabsetConfig,
  NgbDateParserFormatter,
  NgbDateStruct
} from "@ng-bootstrap/ng-bootstrap";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { Router, ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthServices, AuthServices as Auth } from "../services/auth.service";
import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
import countries from "../../assets/country/country.json";
import { MustMatch } from "../helpers/must-watch.validator";
import { TnNotificationService } from "../services/tn-notification.service";
import { NgbDatepickerConfig } from "@ng-bootstrap/ng-bootstrap";
import {
  GoogleLoginProvider,
  FacebookLoginProvider,
  AuthService,
  SocialUser
} from "angularx-social-login";
@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.scss"],
  providers: [NgbTabsetConfig]
})
export class RegisterComponent implements OnInit, DoCheck {
  model: NgbDateStruct;
  dateString: string;
  closed: boolean = false;
  emailVerified: boolean = false;
  basicDetailsForm: any;
  profileForm: any;
  termsConditionForm: any;
  imgUrl: any;
  dateToday: any = {};
  imageName: any;
  profileObject: any;
  selectedFile: any;
  imageObject: any;
  termsObject: any;
  registerObject: any;
  imagePath: any;
  profileObjectdob: any;
  profileImageName: any;
  idd: any = "basic_details_tab";
  public countryList: { name: string; code: string }[] = countries;
  // profileTabHide: boolean = true;
  // investTabHide: boolean = true;
  step = 1;
  activeTab: String;
  emailFromJoin: any;
  userEmailValue: string;
  emailNotExist = false;
  emailExistErr: string;
  emailExists = false;
  passworderror: boolean = false;
  pwdmatch: any;
  // activeTab1:String;
  constructor(
    // config: NgbTabsetConfig,
    private config: NgbDatepickerConfig,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private router: Router,
    private modalService: NgbModal,
    private authService: AuthServices,
    private serviceAuth: AuthService,
    private activatedRoute: ActivatedRoute,
    private notificationService: TnNotificationService,
    public route: ActivatedRoute,
    private ngbDateParserFormatter: NgbDateParserFormatter
  ) {
    const current = new Date();
    config.maxDate = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate()
    };
    config.minDate = { year: 1990, month: 12, day: 31 };
    config.outsideDays = "hidden";

    // config.justify = "end";
    // config.type = "tabs";
  }
  @HostListener("window:unload", ["$event"])
  handleUnload(event) {
    this.authService.logout();
  }
  ngOnInit() {
    this.route.queryParams.subscribe((res: any) => {
      this.userEmailValue = res.email;
    });
    this.dateToday = {
      year: new Date().getFullYear(),
      month: new Date().getMonth(),
      day: new Date().getDate()
    };

    this.activatedRoute.queryParamMap.subscribe((data: any) => {
      this.emailFromJoin = data.params.email;
    });

    if (this.emailFromJoin != null || undefined) {
      this.onReg();
    }

    this.modalService.dismissAll("any");
    this.basicDetailsForm = this.formBuilder.group(
      {
        firstName: ["", Validators.required],
        lastName: ["", Validators.required],
        // dob: ["", Validators.required],
        // dob: [this.setDefaultDate(), Validators.required],
        // email: ["", [Validators.required, Validators.email]],
        email: [
          "",
          [
            Validators.required,
            Validators.email,
            Validators.pattern(
              "^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$"
            )
          ]
        ],
        // placeOfBirth: ["undefined", Validators.required],
        country: ['', Validators.required],
        // receivePromotionalEmails: ["", Validators.required],
        password: [
          null,
          [Validators.required, Validators.compose([Validators.minLength(8), Validators.maxLength(15)])]
        ],
        confirmPassword: ["", Validators.required]
      },
      // {
      //   validator: MustMatch("password", "confirmPassword")
      // }
    );
    this.profileForm = this.formBuilder.group({
      bioData: ["", [Validators.maxLength(500)]]
    });
    this.termsConditionForm = this.formBuilder.group({
      termsCondition: ["", Validators.required]
    });
  }
  onReg() {
    this.step = 2;
  }
  changeTab(tabsec: String) {
    if (tabsec == "basicdetails" && this.step >= 2) {
      this.step = 2;
      this.activeTab = tabsec;
    }
    if (tabsec == "Profile" && this.step >= 3) {
      this.step = 3;
      this.activeTab = tabsec;
    }
  }

  closeAlert() {
    //  alert.splice();
  }
  ngDoCheck() {
    // console.log(this.idd, 'this chekcd');
  }
  skipStep() {
    this.step = 4;
    // this.idd = tab;
    // this.investTabHide = false;
  }
  // tabsetChange(e) {
  //   this.idd = e.nextId;
  //   e.preventDefault();
  // }
  basicDetailsSubmit(data) {
    this.activeTab = '';
    if (data.confirmPassword !== data.password) {
      this.passworderror = true;
      return;
    } else {
      this.passworderror = false;
    }
    // this.profileTabHide = false;
    this.idd = "profile_tab";
    this.emailVerified = true;
    // let dob = data.dob;
    // let datestr = new Date(dob.year + "-" + dob.month + "-" + dob.day);
    // this.profileObjectdob = datestr.toISOString();
    this.profileObject = data;
    this.step = 3;
    console.log(this.profileObject);
  }

  profileSubmit(value) {
    // const fd = new FormData();
    // fd.append('file', this.imagePath);
    const data = {
      // profileImage: this.imgUrl ? this.imgUrl : '',
      bioData: value.bioData
    };
    if (this.imgUrl) {
      data["profileImage"] = this.imgUrl;
    } else {
      data["profileImage"] = "";
    }
    this.imageObject = data;

    // this.investTabHide = false;
    this.idd = "invest_tab";
    this.step = 4;
  }

  onFileSelected(event) {
    const File = (event.target as HTMLInputElement).files[0];
    this.selectedFile = File;
  }

  onUpload() {
    const imageForm = new FormData();
    imageForm.append("image", this.selectedFile, this.selectedFile.name);
    console.log("fd=======", imageForm);
    this.authService.upload(imageForm).subscribe(response => {
      console.log("response======>", response);
      return response;
    });
  }

  termsSubmit(data) {
    this.termsObject = {
      terms: data
    };
    this.registerObject = {
      profile: this.profileObject,
      image: this.imageObject,
      terms: data
    };
    const query = {
      firstName: this.profileObject.firstName,
      lastName: this.profileObject.lastName,
      email: this.profileObject.email,
      password: this.profileObject.password,
      // dob: this.profileObjectdob,
      nationality: this.profileObject.country,
      // receivePromotionalEmails: this.profileObject.receivePromotionalEmails,
      // countryOfResidence: this.profileObject.placeOfBirth,
      profileImage: this.imageObject ? this.imageObject.profileImage : "",
      bioDesc: this.imageObject ? this.imageObject.bioData || "N/A" : "",
      tAndCAgree: this.termsObject.terms.termsCondition,
      provider: "local"
    };
    let fd = new FormData();
    Object.keys(query).forEach(k => {
      fd.append(k, query[k]);
    });
    this.authService.register(fd).subscribe(
      (userData: any) => {
        if (userData["code"] === 201) {
          this.basicDetailsForm.reset();
          this.profileForm.reset();
          this.termsConditionForm.reset();
          this.notificationService.successToast("Registration Successful!");
          this.router.navigateByUrl("/login");
          setTimeout(() => {
            this.notificationService.successToast(
              "Please Verify the Email to login!"
            );
          }, 3000);
        }
      },
      err => {
        if (err.error.code == 417) {
          this.notificationService.infoToast("Email already exists");
        }
      }
    );
  }
  // fileChangeEvent(event: any): void {
  //   if (event.target.files && event.target.files[0] && (event.target.files[0].type === 'image/png' ||
  //     event.target.files[0].type === 'image/jpg' || event.target.files[0].type === 'image/jpeg')) {
  //     const FileSize = event.target.files[0].size / 1024 / 1024;
  //     if (FileSize > 5) {
  //       this.toastrService.error('Image size should not be greater than 5MB');
  //     } else {
  //       this.imageName =  event.target.files[0].name;
  //       this.imagePath =  event.target.files[0];
  //       const reader = new FileReader();
  //       reader.readAsDataURL(event.target.files[0]); // read file as data url
  //       reader.onload = (e) => {
  //          this.imgUrl = reader.result;
  //       };
  //     }
  //   } else {
  //     this.toastrService.error('Only jpg / jpeg / png formats are allowed ');
  //   }
  // }
  fileChangeEvent(event: any, type: any): void {
    if (
      event.target.files &&
      // event.target.files[0] &&
      (event.target.files[0].type === "image/png" ||
        event.target.files[0].type === "image/jpg" ||
        event.target.files[0].type === "image/jpeg")
    ) {
      const FileSize = event.target.files[0].size;
      if (FileSize > 5242880) {
        this.toastrService.error("Image size should not be greater than 5MB");
      } else {
        this.profileImageName = event.target.files[0].name;
        this.imagePath = event.target.files[0];
        if (type === "profile") {
          this.imgUrl = this.imagePath;
        }
        const reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]); // read file as data url
        reader.onload = e => {
          if (type === "profile") {
            this.imageName = reader.result;
          }
          // this.imgUrl = reader.result;
        };
      }
    }
    else {
      this.toastrService.error("Only jpg / jpeg / png formats are allowed ");
    }
  }
  keyPress(event: any) {
    const pattern = /[a-zA-Z \-\']/;

    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  socialSignIn(socialProvider) {
    if (socialProvider === "google") {
      this.serviceAuth
        .signIn(GoogleLoginProvider.PROVIDER_ID)
        .then((data: any) => {
          const queryParams = {
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName,
            socialMedia: true,
            provider: socialProvider,
            token: data.idToken,
            authToken: data.authToken
          };
          this.authService
            .socialLogin(queryParams)
            .subscribe((response: any) => {
              if (response.code === 200 || response.code === 201) {
                localStorage.setItem(
                  "currentUser",
                  response.result.accessToken
                );
                localStorage.setItem("uuid", response.result.user.uuid);
                this.notificationService.successToast("Login Successfully");
                this.router.navigate(["/home-main"]);
              }
            });
        });
    } else if (socialProvider === "facebook") {
      this.serviceAuth
        .signIn(FacebookLoginProvider.PROVIDER_ID)
        .then((data: any) => {
          const queryParams = {
            email: data.facebook.email,
            firstName: data.firstName,
            lastName: data.lastName,
            socialMedia: true,
            provider: socialProvider,
            authToken: data.authToken
          };
          this.authService
            .socialLogin(queryParams)
            .subscribe((response: any) => {
              if (response.code === 200 || response.code === 201) {
                localStorage.setItem(
                  "currentUser",
                  response.result.accessToken
                );
                localStorage.setItem("uuid", response.result.user.uuid);
                this.notificationService.successToast("Login Successfully");
                this.router.navigate(["/home-main"]);
              }
            });
        });
    }
  }
  onSelectDate(date: NgbDateStruct) {
    if (date != null) {
      this.model = date; //needed for first time around due to ngModel not binding during ngOnInit call. Seems like a bug in ng2.
      this.dateString = this.ngbDateParserFormatter.format(date);
    }
  }

  setDefaultDate(): NgbDateStruct {
    var startDate = new Date();
    let startYear = startDate.getFullYear().toString();
    let startMonth = startDate.getMonth() + 1;
    let startDay = "1";
    // console.log(this.ngbDateParserFormatter.parse(startYear + "-" + startMonth.toString() + "-" + startDay), "DEFAULT DATE");
    return this.ngbDateParserFormatter.parse(
      startMonth.toString() + "-" + startDay + "-" + startYear
    );
  }

  checkEmail(email) {
    const verifyEmailBody = {
      // tslint:disable-next-line: object-literal-shorthand
      email: email
    };
    this.authService.checkUserEmail(verifyEmailBody).subscribe((response: any) => {
      console.log(response.code);
    },
      err => {
        if (err.error.code === 403) {
          this.emailExists = true;
          this.emailNotExist = false;
          this.emailExistErr = err.error.result;
        } else if (err.error.code === 404) {
          this.emailExists = false;
          this.emailNotExist = true;
        }
      });
  }
  onKey(data) {
    if (this.basicDetailsForm.value['password']  === data) {
      this.passworderror = false;
    }
  }
}
