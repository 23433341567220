import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthServices, AuthServices as Auth } from "../services/auth.service";
import { query } from "@angular/animations";
import { ToastrService } from "ngx-toastr";
import { TnNotificationService } from "../services/tn-notification.service";
import {
  AuthService
} from "angularx-social-login";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.scss"]
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordVerify: FormGroup;
  submit: boolean = false;
  emailId: any = "";
  user: any;
  emailFromJoin: any;

  emailNotExist = false;
  emailExistErr: string;
  emailExists = false;
  emailNotVerify = false;
  socilLogin = false;
  successMsg = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthServices,
    public toastrService: ToastrService,
    private notificationService: TnNotificationService,
    private serviceAuth: AuthService,
    private activatedRoute: ActivatedRoute,
    public route: ActivatedRoute
  ) { }

  // ONCHANGE(e) {
  //   var re = /[`~!#$%^&*()_|+\-=?;:'",<>\{\}\[\]\\\/]/;
  //   if (re.test(e.target.value)) {
  //     e.preventDefault();
  //   }
  // }

  ngOnInit() {
    this.route.queryParams.subscribe((res: { email: string }) => {
      this.emailId = res.email;
    });
    this.activatedRoute.queryParamMap.subscribe((data: any) => {
      this.emailFromJoin = data.params.email;
    });
    this.forgotPasswordVerify = this.formBuilder.group({
      email: ["", Validators.required]
    });
    this.serviceAuth.authState.subscribe(user => {
      this.user = user;
    });

  }

  forgotPasswordMail(email) {
    this.submit = true;
    this.authService.forgotPasswordMail(email).subscribe(
      (response: any) => {
        if (response.code === 200) {
          // this.notificationService.successToast(
          //   "Please check the link sent to " + emailId.email + "."
          // );
          this.successMsg = true;
          setTimeout(() => {
            this.router.navigateByUrl("/login");
          }, 1000);
        }
      },
      err => {
        if (err.error.code === 401 || err.error.code === 400) {
          // this.notificationService.errorToast("Please check the emailId");
          this.emailNotExist = true;
        } else if (err.error.code === 406) {
          this.socilLogin = true;
          // this.notificationService.errorToast(  "User registered through Social media Login!" );
        } else if (err.error.code === 417) {
          this.emailNotVerify = true;
          // this.notificationService.errorToast(
          //   "User Email not verified!"
          // );
        }
      }
    );
  }
  checkEmail(email) {
    const verifyEmailBody = {
      // tslint:disable-next-line: object-literal-shorthand
      email: email
    };
    this.authService.checkUserEmail(verifyEmailBody).subscribe((response: any) => {
      console.log(response.code);
    },
      err => {
        if (err.error.code === 403) {
          this.emailExists = true;
          this.emailNotExist = false;
          this.emailExistErr = err.error.result;
        } else if (err.error.code === 404) {
          this.emailExists = false;
          this.emailNotExist = true;
        }
      });
  }
}
