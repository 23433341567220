import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { veSearchPipe } from "../pipes/search.pipe";

@NgModule({
  declarations: [veSearchPipe],
  imports: [CommonModule],
  exports: [veSearchPipe]
})
export class PipeModule {}
