import { Injectable } from "@angular/core";
import { HttpHeaders, HttpClient, HttpEventType } from "@angular/common/http";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export default class UploadService {
  public baseURL = "";
  constructor(public http: HttpClient) {}
  public upload(data) {
    let uploadURL = this.baseURL + "/uploadFile";

    return this.http
      .post<any>(uploadURL, data, {
        headers: new HttpHeaders({
          Authorization: localStorage.getItem("currentUser")
        }),
        reportProgress: true,
        observe: "events"
      })
      .pipe(
        map(event => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              const progress = Math.round((100 * event.loaded) / event.total);
              return { status: "progress", message: progress };

            case HttpEventType.Response:
              return event.body;
            default:
              return `Unhandled event: ${event.type}`;
          }
        })
      );
  }
}
